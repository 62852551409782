<template>
  <div class="user-wrapper">
    <div class="content-box">
      <!--<a href="https://pro.loacg.com/docs/getting-started" target="_blank">-->
      <!--<span class="action">-->
      <!--<a-icon type="question-circle-o"></a-icon>-->
      <!--</span>-->
      <!--</a>-->
      <!--<notice-icon class="action"/>-->
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar class="avatar" size="small" :src="avatar()"/>
          <span style="color: white;margin-top: 6px;">{{ nickname() }}</span>
        </span>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
          <a-menu-item key="1">
            <a href="javascript:;" @click="showUInfo">
              <a-icon type="setting"/>
              <span>账户设置</span>
            </a>
          </a-menu-item>
          <a-menu-divider/>
          <a-menu-item key="2" v-if="role==2">
            <a href="javascript:;" @click="showVisitQR">
              <a-icon type="qrcode"/>
              <span>邀请二维码</span>
            </a>
          </a-menu-item>
          <a-menu-divider v-if="role==2"/>
          <a-menu-item key="3">
            <a href="javascript:;" @click="handleLogout">
              <a-icon type="logout"/>
              <span>退出登录</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import NoticeIcon from '@/components/NoticeIcon'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import VisitQr from '@/views/staff/VisitQr'

export default {
  name: 'UserMenu',
  data () {
    return {
      role: 0
    }
  },
  components: {
    NoticeIcon
  },
  created () {
    var role = Vue.ls.get('role')
    this.role = role
  },
  methods: {
    ...mapActions(['Logout']),
    ...mapGetters(['nickname', 'avatar']),
    handleLogout () {
      const that = this

      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk () {
          return that.Logout({}).then(() => {
            window.location.reload()
          }).catch(err => {
            that.$message.error({
              title: '错误',
              description: err.message
            })
          })
        },
        onCancel () {
        }
      })
    },
    showUInfo () {
      this.$emit('showAccountModal')
    },
    showVisitQR () {
       this.showModal(VisitQr, '我的邀请码', 320, 420)
    }
  }
}
</script>
