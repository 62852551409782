exports.install = function (Vue, options) {
  Vue.prototype.showModal = function (componentName, title, width, height, data) {
    this.$layer.iframe({
      content: {
        content: componentName, // 传递的组件对象
        parent: this, // 当前的vue对象
        data: data || {}// props
      },
      area: [width + 'px', height + 'px'],
      title: title,
      shadeClose: false
    })
  }
  Vue.prototype.hideModalForResult = function (res, option) {
    if (res.code === 0) {
      if (option && option.success) {
        option.success()
      }
      this.$layer.close(this.layerid)
    } else {
      this.$message.error(res.msg)
    }
  }
  Vue.prototype.saveResultAsExcel=function(res,filename){
    if (!res) {
      return
    }
    const url = window.URL.createObjectURL(new Blob([res]))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }
}
