import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import jsSHA from 'jssha'
import {
  VueAxios
} from './axios'
import notification from 'ant-design-vue/es/notification'
import {
  ACCESS_TOKEN,
  USERID
} from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: '/api', // api base_url
  timeout: 6000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: '禁止访问',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: '认证失败',
        description: '认证过期'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      } else {
        window.location.href = '/user/login'
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  const userid = Vue.ls.get(USERID)
  if (token) {
    config.headers['Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    config.headers['USERID'] = userid
    config.headers['client'] = 'pc'
  }
  if (config.method === 'post' && config.data && (typeof config.data === 'object')) {
    var args = Object.assign({}, config.data)
    args.requestUrl = config.url
    args.requestTime = (new Date()).getTime()
    args.requestNonce = Math.random().toString(36).substr(2, 15)
    config.headers['requestTime'] = args.requestTime
    config.headers['requestNonce'] = args.requestNonce
    var keys = Object.keys(args)
    keys = keys.sort()
    var newArgs = {}
    keys.forEach(function (key) {
      newArgs[key.toLowerCase()] = args[key]
    })

    var string = ''
    for (var k in newArgs) {
      if (newArgs[k] !== undefined) {
        string += '&' + k + '=' + newArgs[k]
      }
    }
    string = string.substr(1)
    // eslint-disable-next-line new-cap
    var shaObj = new jsSHA('SHA-1', 'TEXT')
    shaObj.update(string)
    var signature = shaObj.getHash('HEX')
    config.headers['sign'] = signature
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
