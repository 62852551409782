// eslint-disable-next-line
import {UserLayout, BasicLayout, RouteView, BlankLayout, PageView} from '@/layouts'

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    children: [
      // dashboard
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/main',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: '首页', keepAlive: true, icon: 'icon-tubiao-zhuzhuangtu', permission: ['dashboard'] },
        children: [
          {
            path: '/dashboard/main',
            name: 'MainPage',
            component: resolve => require(['@/views/dashboard/MainChart'], resolve),
            meta: { title: '首页', keepAlive: true, permission: ['dashboard'] }
          }
        ]
      },
      {
        path: '/weigh',
        name: 'weigh',
        redirect: '/weigh/record',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: '称重记录', keepAlive: true, icon: 'icon-jilu-01', permission: ['weigh'] },
        children: [
          {
            path: '/weigh/record',
            name: 'DevMonitor',
            component: resolve => require(['@/views/weigh/WeighRecord'], resolve),
            meta: { title: '称重记录', keepAlive: true, permission: ['weigh'] }
          }
        ]
      },
      {
        path: '/user',
        name: 'user',
        redirect: '/user/list',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: '用户管理', keepAlive: true, icon: 'icon-yonghu', permission: ['user'] },
        children: [
          {
            path: '/user/list',
            name: 'UserList',
            component: resolve => require(['@/views/user/UserList'], resolve),
            meta: { title: '用户管理', keepAlive: true, permission: ['user'] }
          }
        ]
      },
      {
        path: '/feedback',
        name: 'feedback',
        redirect: '/feedback/list',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: '用户反馈', keepAlive: true, icon: 'icon-wodefankui', permission: ['feedback'] },
        children: [
          {
            path: '/feedback/list',
            name: 'UserFeedback',
            component: resolve => require(['@/views/feedback/UserFeedback'], resolve),
            meta: { title: '用户反馈', keepAlive: true, permission: ['feedback'] }
          }
        ]
      },
      {
        path: '/device',
        name: 'device',
        redirect: '/device/list',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: '设备管理', keepAlive: true, icon: 'icon-dianzicheng', permission: ['device'] },
        children: [
          {
            path: '/device/list',
            name: 'Device',
            component: resolve => require(['@/views/device/Device'], resolve),
            meta: { title: '设备管理', keepAlive: true, permission: ['device'] }
          }
        ]
      },
      {
        path: '/unit',
        name: 'unit',
        redirect: '/unit/list',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: '客户管理', keepAlive: true, icon: 'icon-kehu', permission: ['unit'] },
        children: [
          {
            path: '/unit/list',
            name: 'UnitList',
            component: resolve => require(['@/views/unit/UnitList'], resolve),
            meta: { title: '客户管理', keepAlive: true, permission: ['unit'] }
          }
        ]
      },
      {
        path: '/staff',
        name: 'staff',
        redirect: '/staff/list',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: '员工管理', keepAlive: true, icon: 'icon-kehu', permission: ['staff'] },
        children: [
          {
            path: '/staff/list',
            name: 'StaffList',
            component: resolve => require(['@/views/staff/StaffList'], resolve),
            meta: { title: '员工管理', keepAlive: true, permission: ['staff'] }
          }
        ]
      },
      {
        path: '*', redirect: '/404', hidden: true
      }
    ],
    meta: { title: '首页' },
    redirect: '/dashboard/main'
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: resolve => require(['@/views/user/Login'], resolve)
      },
      {
        path: 'register',
        name: 'register',
        component: resolve => require(['@/views/user/Register'], resolve)
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: resolve => require(['@/views/user/RegisterResult'], resolve)
      }
    ]
  },
  {
    path: '/404',
    component: resolve => require(['@/views/exception/404'], resolve)
  }

]
