<template>
  <div style="width: 100%;padding: 6px;">
    <div>
      <img :src="imageBase64" style="width: 300px;height:300px;"/>
    </div>
    <div style="margin-top: 8px;">发送以上二维码给客户，客户扫描注册后可自动授权查看其称重数据。</div>
  </div>
</template>

<script>
import { genMiniProgramQr } from '@/api/pc'

export default {
  name: 'VisitQr',
  data () {
    return {
      imageBase64: null
    }
  },
  created () {
    genMiniProgramQr().then(res => {
      this.imageBase64 = res
    })
  }
}
</script>

<style scoped>

</style>
