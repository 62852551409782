import { axios } from '@/utils/request'

export function loadWeightRecords (parameter) {
  return axios({
    url: '/weigh/user/record/list',
    method: 'post',
    data: parameter
  })
}

export function loadUserList (parameter) {
  return axios({
    url: '/backend/user/list',
    method: 'post',
    data: parameter
  })
}

export function loadUnitList (parameter) {
  return axios({
    url: '/backend/unit/list',
    method: 'post',
    data: parameter
  })
}

export function loadUserInfo (parameter) {
  return axios({
    url: '/backend/user/info/load',
    method: 'post',
    data: parameter
  })
}

export function loadUserWeightHistory (parameter) {
  return axios({
    url: '/backend/user/weight/list',
    method: 'post',
    data: parameter
  })
}

export function deleteUnit (parameter) {
  return axios({
    url: '/backend/unit/del',
    method: 'post',
    data: parameter
  })
}

export function saveUnitAdminInfo (parameter) {
  return axios({
    url: '/backend/unit/admin/info/save',
    method: 'post',
    data: parameter
  })
}

export function loadStaffList (parameter) {
  return axios({
    url: '/backend/unit/staff/list',
    method: 'post',
    data: parameter
  })
}

export function deleteStaff (parameter) {
  return axios({
    url: '/backend/unit/staff/del',
    method: 'post',
    data: parameter
  })
}

export function toggleStaffStatus (parameter) {
  return axios({
    url: '/backend/unit/staff/status/update',
    method: 'post',
    data: parameter
  })
}

export function loadDeviceList (parameter) {
  return axios({
    url: '/backend/device/list',
    method: 'post',
    data: parameter
  })
}

export function loadAllUnitInfos (parameter) {
  return axios({
    url: '/backend/unit/all/load',
    method: 'post',
    data: parameter
  })
}

export function loadWeighCountTrendData (parameter) {
  return axios({
    url: '/backend/weigh/trend/data',
    method: 'post',
    data: parameter
  })
}

export function loadWeighCountDistributionData (parameter) {
  return axios({
    url: '/backend/weigh/distribution/data',
    method: 'post',
    data: parameter
  })
}

export function loadUserCountTrendData (parameter) {
  return axios({
    url: '/backend/user/count/trend/data',
    method: 'post',
    data: parameter
  })
}

export function saveUserName (parameter) {
  return axios({
    url: '/backend/user/name/save',
    method: 'post',
    data: parameter
  })
}

export function saveCustomerName (parameter) {
  return axios({
    url: '/backend/customer/name/save',
    method: 'post',
    data: parameter
  })
}

export function exportUserData (parameter) {
  return axios({
    url: '/backend/user/list/export',
    method: 'post',
    data: parameter,
    responseType: 'blob'
  })
}

export function exportWeightRecords (parameter) {
  return axios({
    url: '/backend/weight/list/export',
    method: 'post',
    timeout: 120000,
    data: parameter,
    responseType: 'blob'
  })
}

export function saveStaffInfo (parameter) {
  return axios({
    url: '/backend/staff/info/save',
    method: 'post',
    data: parameter
  })
}

export function resetInfo (parameter) {
  return axios({
    url: '/backend/admin/info/reset',
    method: 'post',
    data: parameter
  })
}

export function genMiniProgramQr (parameter) {
  return axios({
    url: '/backend/visit/qr',
    method: 'post',
    data: parameter
  })
}
