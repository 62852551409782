<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
/* eslint-disable camelcase */

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { AppDeviceEnquire } from '@/utils/mixin'
import moment from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')

export default {
  mixins: [AppDeviceEnquire],
  data () {
    return {
      locale: zhCN
    }
  },
  mounted () {

  },
  beforeDestroy () {
    this.$layer.closeAll()
  },
  methods: {
  }

}
</script>
<style>
  @import "./assets/common.css";
  @import "./assets/vue-layer.css";
  #app {
    height: 100%;
    width: 100%;
  }
  body{
    padding: 0;
  }

</style>
